html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}
