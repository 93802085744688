.react-dadata__container {
  position: relative;
  width: 100%;
  border: 0;

  & > div {
    display: flex;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  & .MuiOutlinedInput-root {
    height: 41px;
    padding: 10px 18px;
    padding-right: 32px !important;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.17px;
    color: #2B3648;
    background-color: #F6F7FB;
    border-radius: 8px;
  }

  & .MuiOutlinedInput-input {
    padding: 0;
  }

  & .MuiOutlinedInput-notchedOutline {
    top: 0;
    border: 1px solid rgba(92, 110, 140, 0.1);

    & legend {
      padding: 0;

      & span {
        display: none;
      }
    }
  }

  & .MuiAutocomplete-endAdornment {
    top: calc(50% - 11px);
    right: 8px;

    & .MuiSvgIcon-root {
      color: #2B3648;
      font-size: 16px !important;
    }
  }

  & .MuiFormHelperText-root {
    text-align: right;
  }

  & .react-dadata__suggestions {
    padding: 8px 0;
  }

  & .react-dadata__suggestion {
    min-height: 36px;
    padding: 6px 18px;

    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;

    &:hover {
      background-color: #F6F7FB;
    }
  }

  .react-dadata--highlighted {
    color: #0044B4;
  }
}
