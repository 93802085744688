:root {
    --primary: rgba(92, 110, 140, 0.1);
    --primary-hover: black;
    --white: white;
    --background-color: white;
    --disabled: white;
    --disabled-button: white;
}

.select {
    position: relative;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid var(--primary);
    color: #2b3648;
    background-color: #f6f7fb;
    letter-spacing: 0.1em;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 36px;
    letter-spacing: inherit;
}

.error {
    border: 1px solid red;
}

.select.active {
    outline: 1px solid rgba(92, 110, 140, 0.1);
}

.dropDown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 185px;
    overflow: auto;
    background-color: var(--white);
    /*padding: 8px 0 8px;*/
    z-index: 200;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.option {
    padding: 6px 16px;
    color: #2b3648;
    background: #edeff3;
    /*border-radius: 8px;*/

    cursor: pointer;
    transition: all 0.2s;
}

.option:hover {
    background-color: #f6f7fb;
    color: #0044b4;
    transition: all 0.2s;
}

.arrowDownIcon {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-45%);
    fill: var(--disabled-button);
    transition: all 0.2s;
}

.active > .arrowDownIcon {
    fill: var(--primary);
    transform: translateY(-45%) rotateZ(180deg);
    transition: all 0.2s;
}

.label {
    color: rgb(92, 110, 140);
    font-weight: 400;
    display: block;
    margin-bottom: 6px;
    text-align: left;
    text-transform: none;
    font-size: 12px;
    line-height: 110%;
    letter-spacing: 0;
    padding-left: 16px;
}
