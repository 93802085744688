.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  text-align: center;
  background-color: #edeff3; /* bg/gray */
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

//styles for scrollbar
::-webkit-scrollbar {
  width: 13px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #7890b2; /* secondary/gray */
  border-radius: 9px 8px 8px 9px;
  border-width: 2px 2px 2px 3px;
  border-style: solid;
  border-color: transparent;
  background-clip: content-box;

  &:hover {
    background-color: #849ec3;
  }
}

::-webkit-scrollbar-corner {
  background: transparent;
  border-left: 1px solid rgba(0, 36, 95, 0.1);
}

.fullpagestatus {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  > .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #2b3648;
  }

  > .MuiSvgIcon-root {
    font-size: 56px;
  }
}

.ag-theme-material.accompishment {
  --ag-row-hover-color: rgba(148, 162, 245, 0.36) !important;
  --ag-header-column-separator-color: black !important;
}

.ag-theme-material.accompishment .ag-header {
  //background: rgba(255, 177, 177, 0.44);
  border-top: 1px solid rgb(210, 220, 255);
}

//.ag-theme-material.reference-prices {
//  --ag-header-column-separator-color: black !important;
//  --ag-input-focus-border-color:#e2e2e2 !important;
//  --ag-row-hover-color: rgba(148, 162, 245, 0.36) !important;
//}

.ag-theme-material .ag-header-cell-label {
  justify-content: center;
}

.ag-theme-material .ag-header-group-cell-label {
  justify-content: center;
}

.ag-theme-material .ag-header-viewport {
  // border-bottom: 1px solid rgb(210, 220, 255);
}

.ag-keyboard-focus .ag-header-group-cell:focus::after {
  display: none !important;
}

.ag-theme-material .ag-header.ag-pivot-off.ag-header-allow-overflow {
  white-space: normal;
  text-align: center;
  z-index: 2;
  box-shadow: 0 1px 4px rgba(109, 154, 220, 0.5);
  overflow: visible;
}

.ag-theme-material .ag-cell {
  padding: 4px 8px;
}

.ag-theme-material .ag-cell-wrapper {
  height: 100%;
}

.ag-theme-material .ag-cell-label-container {
  padding: 0 !important;
}

//.ag-theme-material .ag-cell.ag-cell-normal-height.ag-cell-inline-editing{
//  background: transparent;
//  border:none;
//  width: 100%;
//  height: 100%;
//  box-shadow: none;
//  padding: 0;
//  //display: flex;
//  //align-items: center;
//  //justify-content: center;
//  .ag-cell-wrapper{
//    width: 100%;
//    height: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//
//  }
//}
.ag-theme-material.accompishment .ag-cell.ag-cell-normal-height.ag-cell-inline-editing {
  background: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;

  .ag-cell-wrapper {
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//.ag-theme-material.reference-prices .ag-cell.ag-cell-normal-height.ag-cell-inline-editing{
//  background: transparent;
//  border:none;
//  width: 100%;
//  height: 100%;
//  box-shadow: none;
//  //padding: 0;
//  display: flex;
//  align-items: center;
//  justify-content: center;
//  .ag-cell-wrapper{
//    width: 100%;
//    height: 100%;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    //.ag-cell-value{
//    //  height: 100% !important;
//    //}
//  }
//}
.ag-theme-material .ag-cell-inline-editing {
  height: 100% !important;
}

.ag-theme-material .focusedCol {
  border-left: 1px solid rgb(4, 49, 123) !important;
  border-right: 1px solid rgb(4, 49, 123) !important;
  border-top: 1px solid rgb(4, 49, 123) !important;
}

.ag-theme-material .focusedColTop {
  border-left: 1px solid rgb(4, 49, 123) !important;
  border-right: 1px solid rgb(4, 49, 123) !important;
}

.ag-theme-material .ag-header-cell:focus,
.ag-theme-material .ag-header-cell:hover {
  outline: none;
  background-color: transparent;
}

.ag-theme-material .ag-header-row .ag-header-cell {
  padding: 0 4.5px;
  //padding: 20px 8px 10px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #00358c;
  line-height: 13px;
  letter-spacing: 0.1px;
  text-align: center;
}

.ag-theme-material .ag-header-cell {
  border-right: 1px solid rgb(210, 220, 255);
}

.ag-theme-material .ag-row-pinned {
  border-bottom: 2px solid rgb(210, 220, 255);
}

.ag-theme-material .ag-header-group-cell,
.ag-theme-material .ag-header-group-cell.ag-header-group-cell-with-group {
  border-right: 1px solid rgb(210, 220, 255);
}

//.ag-theme-material .cell-bg-file.ag-row-hover, .ag-theme-material .cell-bg-level.ag-row-hover, .ag-theme-material .cell-bg-def.ag-row-hover{
//  background: rgb(176, 192, 255) !important; /* Сбросить цвет фона на начальное значение */
//}
////.ag-row ag-row-level-0 cell-bg-file ag-row-position-absolute ag-row-first ag-row-not-inline-editing ag-row-focus"
//.ag-theme-material  {
//  .cell-bg-file.ag-row-hover,
//  .cell-bg-level.ag-row-hover,
//  .cell-bg-def.ag-row-hover{
//  }
//}
.ag-theme-material .ag-header-row .ag-header-cell:last-of-type {
  //border:none;
}

.ag-theme-material .ag-header-group-cell.ag-header-group-cell-with-group {
  padding: 0 4.5px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #00358c;
  line-height: 13px;
  letter-spacing: 0.1px;
  justify-content: center;
}

.ag-theme-material .ag-header-cell,
.ag-theme-material .ag-header-group-cell.ag-header-group-cell-with-group {
  &:hover {
    background-color: transparent !important; /* Убираем фон при ховере */
  }
}

.ag-theme-material .cell-bg-file {
  background: rgba(0, 68, 180, 0.08);

  &-inactive {
    color: #b8b8b8;
  }

  &-deleted {
    color: #b8b8b8;
  }

  &-dont_belong {
    color: #ff0101;
  }
}

.ag-theme-material .cell-bg-level {
  background: rgba(2, 136, 209, 0.05);

  &-inactive {
    color: #b8b8b8;
  }

  &-deleted {
    color: #b8b8b8;
  }

  &-dont_belong {
    color: #ff0101;
  }
}

.ag-theme-material .cell-bg-def {
  background: #fff;

  &-inactive {
    color: #b8b8b8;
  }

  &-deleted {
    color: #b8b8b8;
  }

  &-dont_belong {
    color: #ff0101;
  }
}

.header-top-border {
  border: 2px solid rgb(4, 49, 123) !important;
  border-bottom: none !important;
}

.header-left-border {
  border-left: 2px solid rgb(4, 49, 123) !important;
}

.header-right-border {
  border-right: 2px solid rgb(4, 49, 123) !important;
}

.header-top-border-executed {
  border: 1px solid #2b3648 !important;
  border-bottom: none !important;
}

.header-left-border-executed {
  border-left: 1px solid #2b3648 !important;
}

.header-right-border-executed {
  border-right: 1px solid #2b3648 !important;
}

.header-complicated {
  vertical-align: center;
  overflow: hidden;

  text-overflow: ellipsis;
  text-align: center;

  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ag-theme-material .cell-bg-file-resources {
  background: rgb(232, 241, 251);
  color: rgb(0, 68, 180);
}

.ag-theme-material .cell-bg-level-resources {
  background: rgb(246, 247, 251);
  color: rgb(92, 110, 140);
}

.ag-theme-material .cell-bg-def-resources {
  background: #fff;
}

.ag-theme-material .cell-bg-sum-resources {
  background: #fff;
  color: rgb(92, 110, 140);
}

.ag-theme-material .cell-bg-resource-reference {
  &__first {
    background: #e5ecf7;
    color: #0044b4;
    border-color: #7398d5;
  }

  &__second {
    background: #f2f6fb;
    color: #5c6e8c;
    border-color: #aad5ee;
  }

  &__third {
    background: #f8fcfc;
    color: #5c6e8c;
    border-color: #cfeee9;
  }
}

//.ag-theme-material.reference-prices .ag-cell .border-left {
//  border-left-color: black !important;
//}
//.ag-theme-material.reference-prices .ag-cell.ag-cell-normal-height.ag-cell-inline-editing{
//  box-shadow: none !important;
//}
//.reference-prices.ag-theme-material .ag-cell.ag-cell-inline-editing:has(.ag-has-focus){
//
//  background: black !important;
//}

//.border-left-price-ref{
//  border-left: 1px solid #e2e2e2 !important;
//}
//.border-right-price-ref{
//  border-right: 1px solid #e2e2e2 !important;
//}
//.grouped-unit-resource{
//  padding: 0 !important;
//  span{
//    height: 100%;
//  }
//}
.index-methods-calculation {
  border-radius: 20px;
  .ag-header-cell-label {
    white-space: normal; /* Позволяет тексту переноситься на следующую строку */
    overflow: hidden; /* Скрывает текст, выходящий за пределы */
    text-overflow: ellipsis; /* Добавляет троеточие для длинного текста */
    display: -webkit-box; /* Для многострочного обрезания текста */
    -webkit-line-clamp: 2; /* Количество видимых строк */
    -webkit-box-orient: vertical; /* Ориентация текста для многострочного обрезания */
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-group {
    .ag-header-group-cell-label {
      height: 16px;
      align-self: center;
      width: 100%;
      background-color: #0288D1;
      color: white;
      border-radius: 6px;

      .ag-header-group-text {
        font-weight: 400 !important;

      }
    }
  }

  .limit-row {

    background-color: #D1D8FA;
  }
}
.with-shadow .ag-header.ag-pivot-off.ag-header-allow-overflow{
    white-space: normal;
    text-align: center;
    z-index: 2;
    box-shadow: 0 1px 4px rgba(109, 154, 220, 0.5);
    overflow: visible;
}

.index-method-parameters-dialog {

  .group {
    background-color: #F6F7FB;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.2px;
    color: #2B3648;
  }

  .position {
    background-color: #F6F7FB;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.2px;
    color: #2B3648;
  }

  .empty {
    border: none
  }
}
